import React, { useState, useRef, useEffect } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { render } from "react-dom"
import { getRandomInt } from "../utils"
import Seo from "../components/seo"
import Layout from "../components/layout"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHome, faLightbulbOn } from "@fortawesome/pro-duotone-svg-icons"

const NotFoundPage = () => {
  const {
    markdownRemark: {
      frontmatter: { projects },
    },
  } = useStaticQuery(graphql`
    query ProjectsQuery {
      markdownRemark(frontmatter: { slug: { eq: "/Projects" } }) {
        frontmatter {
          slug
          projects {
            slug
            title
          }
        }
      }
    }
  `)

  const buttonContainer = useRef()

  const [project] = useState(projects[getRandomInt(0, projects.length - 1)])

  const ShuffledButtons = () => (
    <>
      <Link to="/" className="btn-primary">
        <FontAwesomeIcon size="lg" className="mr-2" icon={faHome} />
        Start from home
      </Link>
      <Link
        title={project.title}
        to={`/Projects/${project.slug}`}
        className="btn-secondary"
      >
        <FontAwesomeIcon size="lg" className="mr-2" icon={faLightbulbOn} />
        Explore a project
      </Link>
    </>
  )

  useEffect(() => {
    const container = buttonContainer.current
    render(<ShuffledButtons />, container)
  }, [])

  return (
    <Layout>
      <Seo title="404: Not found" />
      <section className="min-h-screen-footer grid place-items-center relative">
        <div className="lg:relative py-8 sm:py-24 lg:py-24 container relative overflow-hidden">
          <img
            draggable="false"
            className="lg:block md:block hidden absolute right-0 -top-20 pointer-events-none -z-1"
            alt="Section Pattern"
            src="https://storage.googleapis.com/keshif-website/Illustrations/Pattern.svg"
          />
          <div className="max-w-md sm:max-w-3xl lg:max-w-6xl lg:grid lg:grid-cols-2">
            <div>
              <h4 className="mb-12 text-gray-500">404 Page Not Found</h4>
              <h1>You took an unknown path!</h1>
              <hr className="w-12 bg-transparent border-t-2 border-purple-600 mt-4 mb-6" />
              <h4>
                Sorry, we couldn't find the page you were looking for.
                <br /> But, we can help you discover unknown paths and insights
                in data!
              </h4>
              <div ref={buttonContainer} className="flex gap-5 mt-10"></div>
            </div>
          </div>
          <div className="sm:relative lg:absolute lg:inset-y-0 lg:right-20 lg:w-4/12 w-8/12 max-w-2xl lg:py-0 py-8 mx-auto">
            <div className="relative sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full">
              <img
                alt="404 Page not found"
                className="w-full"
                src="https://storage.googleapis.com/keshif-website/Illustrations/404.png"
              />
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 w-full bg-404Gradient h-14"></div>
      </section>
    </Layout>
  )
}

export default NotFoundPage
